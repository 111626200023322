<template>
  <div class="amap-wrapper" style="width: 100%;height: 500px;" :style="{ width: amap_width, height: amap_height }">
    <a-input v-if="amap_is_search" id="input" v-model="searchName" @input="mapSearch(searchName)" placeholder="请输入地址查询" class="search-box"/>
    <div id="map"></div>
    <div id="result_container">
      <div class="places_wrapper"></div>
  </div>
  </div>
</template>

<script>
// 高德地图
import VueAMap from 'vue-amap'
import { AMapManager } from 'vue-amap'
const amapManager = new AMapManager()
import { debounce, replaceStrAmis } from '@/utils/util'
// import createInfoWindow from './utils/createInfoWindow'


var self = null
export default {
  props: {
    width: {
      type: String || Number,
      default: '90%'
    },
    height: {
      type: String || Number,
      default: '500px'
    },
    isSearch: {
      type: Boolean,
      default: true
    },
    // province: {
    //   type: String,
    //   default: ''
    // },
    // city: {
    //   type: String,
    //   default: ''
    // },
    // area: {
    //   type: String,
    //   default: ''
    // },
    // address: {
    //   type: String,
    //   default: ''
    // },
    lat: {
      type: [String, Number],
      default: null
    },
    lng: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    self = this
    return {
      dataIndex:0,
      amapManager,
      searchName: null,
      zoom: 6,
      center: [116.396304, 39.909847],
      events: null,
      markers: [],
      // polygons:[],
      latitude: '11111111',
      searchOption: {
        city: '',
        citylimit: false
      },
      plugin: [],
      errorInfo: {
        'INVALID_USER_KEY': 'key不正确或过期',
        'SERVICE_NOT_AVAILABLE': '服务不可用',
        'INVALID_USER_SCODE': '安全码未通过验证',
        'INVALID_USER_DOMAIN': '绑定域名无效',
        'USERKEY_PLAT_NOMATCH': '请求key与绑定平台不符',
        'NOT_SUPPORT_HTTPS': '服务不支持https请求',
        'INSUFFICIENT_PRIVILEGES': '权限不足，服务请求被拒绝',
        'USER_KEY_RECYCLED': 'Key被删除',
        'INVALID_PARAMS': '请求参数非法',
        'MISSING_REQUIRED_PARAMS': '缺少必填参数',
        'UNKNOWN_ERROR': '其他未知错误',
        'OUT_OF_SERVICE': '规划点（包括起点、终点、途经点）不在中国陆地范围内',
        'NO_ROADS_NEARBY': '规划点（起点、终点、途经点）附近搜不到路',
        'ROUTE_FAIL': '路线计算失败，通常是由于道路连通关系导致',
        'OVER_DIRECTION_RANGE': '起点终点距离过长',
        'ENGINE_RESPONSE_DATA_ERROR': '服务响应失败'
      },
      polygonObj:{
        // pName: 'Geolocation',
        path: [],
        draggable: false, // 设置多边形是否可拖拽移动
        editable: false, // 多边形当前是否可编辑
        events: {
          init (o) {
            console.log(o)
            console.log(self.path)
            // self.amapManager.getMap().on('click', e => {
            //   console.log(e)
            //   // console.log('边界', self.$res)
            //   console.log('边界', self.$refs.polygons[0].$$getPath())
            //   const pathItemArr = []
            //   pathItemArr[0] = e.lnglat.lng
            //   pathItemArr[1] = e.lnglat.lat
            //   console.log('pathItemArr', pathItemArr)
            //   if (!self.editable) {
            //     return
            //   }
            //   if (self.path.length === 1) {
            //     self.$emit('change', {
            //       pathItem: pathItemArr
            //     })
            //   } else {
            //     const pathArr = self.$refs.polygons[0].$$getPath()
            //     pathArr.push(pathItemArr)
            //     self.$emit('change', {
            //       path: pathArr
            //     })
            //   }
            // })

          },
          change (e) {
            console.log('多边形 改变', e)
            console.log('多边形', self.$refs.polygons[0].$$getPath())
            // console.log('多边形', self)
            console.log(self.markers)
            if (self.markers.length === 0) {
              // self.$emit('change', {
              //     path: self.$refs.polygons[0].$$getPath()
              // })
            } else {
              const getPathArr = self.$refs.polygons[0].$$getPath()
              let isChange = false // 判断 是否要更改父级数据
              self.markersArr = []
              self.pathArr = getPathArr
              if (getPathArr.length === self.markers.length) {
                self.markers.forEach((item, index) => {
                  let itemObj = JSON.parse(JSON.stringify(item))
                  if (!getPathArr[index].includes(item.lng) || !getPathArr[index].includes(item.lat)) {
                    isChange = true
                    itemObj.lng = getPathArr[index][0]
                    itemObj.lat = getPathArr[index][1]
                  }
                  self.markersArr.push(itemObj)
                })
                if(isChange) {
                  console.log('同步数据>>>isChange>>>>',isChange);
                  self.doHandle()
                }else{
                  if(self.$schema.amap_is_polygon_path) {
                    self.setValidData(self.$schema.amap_is_polygon_path, self.markers)
                  }
                }
              } else {
                getPathArr.forEach((item,index) => {
                  let itemObj = {
                    lng: getPathArr[index][0],
                    lat: getPathArr[index][1]
                  }
                  self.markersArr.push(itemObj)
                })

                self.doHandle()
              }
              // if(isChange) {
              //   self.markers = markersArr
              // }
              // if (getPathArr.length === self.markers.length) {

              // } else {
              //   isChange = true
              // }

              console.log('判断 是否要更改父级数据', isChange)

              // if (isChange) {
              //   // self.$emit('change', {
              //   //   path: getPathArr
              //   // })

              // }
            }
          }
        }
      },
      mapObj:null, // 地图实例对象
      infoWindowObj:null,
      aimapObj:null, // 地图实例对象
      MassMarkerLayer:null, // 海里点图层
      searching:null, // 搜索
      container:null, // 搜索容器
    }
  },
  computed: {
    // 多边形
    polygons() {
      let list = []
      let path = []
      this.markers.forEach(item => {
        path.push([item.lng,item.lat])
      })

      this.polygonObj.draggable = this.amap_is_polygon_draggable
      this.polygonObj.editable = this.amap_is_polygon_editable
      this.polygonObj.path = path
      list.push(this.polygonObj)

      console.log('多边形>>>polygons>>>>>>',list);

      return list
    },
    dataList(){
      let mapList = []
      if (this.amap_is_show_more_marker) {
        mapList = this.store.data[this.amap_list]
      } else if (this.amap_is_polygon) {
        mapList = this.store.data[this.amap_is_polygon_path]
      }
      console.log('dataList>>>>>>>>>>>>>>',mapList)
      this.$nextTick(() => {
        this.init()
      })
      return mapList
    }
  },
  beforeMount() {
    // console.log('beforeMount>>>>>>>>>>>',this.amap_key);
  },
  mounted() {
    console.log('self>>>>>1>>>>>', this)

    // this.events = {
    //   init:(o) => {
    //     this.mapObj = o
    //   },
    //   click: (e) => {
    //     // const that = this
    //     if (!e) {
    //       return
    //     }
    //     if(this.infoWindowObj) {
    //       this.infoWindowObj.close()
    //       this.infoWindowObj = null
    //     }
    //     if (!this.amap_is_click&&!this.amap_is_polygon) {
    //       // 不支持点击并且不支持多边形
    //       return
    //     }
    //     const positionArr = []
    //     positionArr.push(e.lnglat.lng)
    //     positionArr.push(e.lnglat.lat)
    //     console.log('e>>>>>', e)
    //     console.log('Vue>>>>>', Vue.markers)
    //     console.log('self>>>>>', self)
    //     console.log('this>>>>>', this)

    //     if(this.amap_is_click&&!this.amap_is_polygon) {
    //       this.markers = []
    //     }
    //     // this.markers.push({ lng: positionArr[0], lat: positionArr[1] });
    //     this.geoCoder(positionArr)
    //   }
    // }
    // const pluginObj = {
    //   pName: 'Autocomplete',
    //   input: 'input',
    //   events: {}
    // }
    // pluginObj.events = {
    //   init: (o) => {
    //     console.log('插件初始化>>>>', o)
    //     console.log('插件初始化>>>>this>>>>', this)

    //     o.on('select', (e) => {
    //       console.log('搜索地图>>>>', e)
    //       console.log('搜索地图>>>>this>>>>', this)

    //       this.markers = []
    //       console.log(e)
    //       if (e.poi.location) {
    //         this.center = [e.poi.location.lng, e.poi.location.lat]
    //         this.markers = [{ lng: e.poi.location.lng, lat: e.poi.location.lat, name: e.poi.name }]
    //         this.geoCoder([e.poi.location.lng, e.poi.location.lat])
    //       }
    //     })
    //   }
    // }
    // this.plugin = []
    // this.plugin.push(pluginObj)
    // this.setValidData('amapDetailInfo', '')
    console.log('添加script>>>>>>');

    this.addScript()
  },
  created() {
    console.log('created>>>>>>>>>>',this.amap_key);
    self = this;
    self.doHandle =  debounce(() => {
      self.markers = self.markersArr
      console.log('同步数据>>>>');
      // let polygonPath = []
      if(this.$schema.amap_is_polygon_path) {
        self.setValidData(self.$schema.amap_is_polygon_path, self.markers)
      }
    },500)
    setTimeout(() => {
      this.$nextTick(() => {
        this.dataIndex = 0;
        this.init()
      })
    }, 1000)

    
  },
  updated() {
    if (this.renderChild && this.body) {
      this.renderChild('body', this.body, 'ReactChildBody')
    }
    // console.log('updated>>>>>data>>>>>amap_list>>>>', this.$data.data[this.amap_list])
  },
  methods: {
    mapInt(lat,lng) {
      console.log('mapInt>>>>>>',lat,lng);
      // 指定 accessToken，用于服务鉴权
      aimap.accessToken = this.amap_key;

      // 指定 baseApiUrl，用于指定服务地址，当处于私有化部署的环境中时，务必注意修改此项
      aimap.baseApiUrl = 'https://location-dev.newayz.com';

      this.aimapObj = new aimap.Map({
        container: 'map',
        // 地图中心点
        center: [121.50547, 31.236532],
        // 地图缩放级别
        zoom: 13,
        // 地图倾斜角度
        pitch: 0,
        // 地图旋转角度
        bearing: 0,
        // 地图样式
        style: 'aimap://styles/aimap/normal-v4',
      });

      // 是否支持点击
      if(this.amap_is_click) {
        this.aimapObj.on('click', (e) => {
          console.log('e>>>>>', e);
          this.placeMarker(e.lngLat)
          
        });
      }
      
      this.aimapObj.on('load', (e) => {
        
        this.searching = new aimap.PlaceSearch();
        this.MassMarkerLayer = new aimap.MassMarkerLayer({
          map: this.aimapObj,
          data: [
            // {
            //     id: "1",
            //     name: "中文-1",
            //     icon: "icon",
            //     coordinates: [121.612846, 31.205494],
            // },
          ],
          images: [
            {
                id: "markIcon",
                type: "png",
                url: require('./img/mark.png')
            }
          ],
          style: {
            "text-field": "{name}",
            "text-color": "#00ff00",
            "text-anchor": "top",
            //   "text-offset": [0, -2],
            "icon-anchor": "bottom",
            "icon-image": ["get", "icon"], //获取data中的icon属性值来配置图标
            "icon-size": 1,
          },
        })
        console.log('MassMarkerLayer>>>>>>>>>>', this.MassMarkerLayer);
        if(!this.amap_is_show_window) {
          this.MassMarkerLayer.on('click', (e) => {
              console.log('MassMarkerLayer点击>>>>>>',e)
          });
        }
      });
      
      
    },
    setValidData(key, value) {
      if (this.store.storeType === 'ServiceStore') { // 上级作用域为顶层作用域或者Service
        this.store.reInitData({ [key]: value })
      } else if (this.store.storeType === 'FormStore') { // 上级作用域为表单
        this.store.setValueByName(key, value)
      }
    },
    mapSearch(keyword) {
      const container = document.querySelector('#result_container .places_wrapper');
      this.searching.search(keyword, (error, result) => {
        console.log(result);

        const data = result.places && result.places.length > 0 && result.places.map(item => {
            container.innerHTML += `<div class="place" @click="placeMarker(${item},'search')">${item.name}</div>`
            // return { coordinates: [item.coordinates.lng, item.coordinates.lat] }
        })
    });
    },
    init() {
      let that = this
      console.log('init>>>>>', this.store.data)
      console.log('init>>>>>', this.$data)
      // console.log('init>>>>>amap_is_show_more_marker>>>>', this.amap_is_show_more_marker)
      // 如果需要显示多个标记或者需要显示多边形
      if (this.amap_is_show_more_marker||this.amap_is_polygon) {
        console.log('init>>>>>amap_list>>>>', this.amap_list)
        console.log('init>>>>>data>>>>>amap_list>>>>', this.store.data[this.amap_list])
        let mapList = []
        if (this.amap_is_show_more_marker) {
          mapList = this.store.data[this.amap_list]
        } else if (this.amap_is_polygon) {
          mapList = this.store.data[this.amap_is_polygon_path]
        }

        if (mapList && mapList.length > 0) {
          this.center = []
          this.markers = [
            // { lng: this.center[0], lat: this.center[1], name: label }
          ]
          var markers = []; // 记录标记点
           // 普通点
          var normalMarker = new AMap.Marker({
              offset: new AMap.Pixel(-75, -40),
          });
          mapList.forEach(item => {
            const lng = item[this.amap_longitude]
            // || 116.396304
            const lat = item[this.amap_latitude]
            // || 39.909847
            const label = item[this.amap_name]
            if (lng && lat) {
              if (this.center.length === 0) {
                this.center = [lng, lat]
              }
              this.markers.push(
                { lng: lng, lat: lat, name: label, info: item }
              )

              let labelMarker = new AMap.LabelMarker({
                position:[lng,lat],
                icon: {
                  type: 'image',
                  image: 'https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png',
                  // size: [6, 9],
                  anchor: 'bottom-center',
                  angel: 0,
                  retina: true
                },
                info: item,
                name:label,
                text: {
                  content: label, //要展示的文字内容
                  // direction: "right", //文字方向，有 icon 时为围绕文字的方向，没有 icon 时，则为相对 position 的位置
                  // offset: [-20, -5], //在 direction 基础上的偏移量
                  // //文字样式
                  style: {
                    fontSize: 12, //字体大小
                    fillColor: "#000000", //字体颜色
                    // strokeColor: "#fff", //描边颜色
                    // strokeWidth: 2, //描边宽度
                    padding:'3,3',
                    backgroundColor:'white'
                  },
                }//设置文字对象
              });

              // 事件
              labelMarker.on('click', (e) =>{
                console.log('点击事件>>>>>>>>>>>>',e);
                
                  var position = e.data.data && e.data.data.position;
                  var markerInfo = e.target.De && e.target.De.info;
                  if (!that.amap_is_show_window) {
                    // 判断是否显示标记点详情
                    return
                  }
                  if(position){
                      // normalMarker.setContent(
                      //     '<div class="amap-info-window">'
                      //         + position +
                      //         '<div class="amap-info-sharp"></div>' +
                      //     '</div>');
                      // normalMarker.setPosition(position);
                      // this.mapObj.add(normalMarker); 
                      //信息窗体的内容
                      // var content = [
                      //   "<div class='info'>这是一个自定义窗体，所以内容和样式你都可以自定义<img src='https://webapi.amap.com/images/sharp.png'></div>",
                      // ];
                      // console.log('markerInfo>>>>>>>>>>',markerInfo);
                      // console.log('amap_window_dom>>>>>>>>>>',that.amap_window_dom);
                      
                      const descriptionTem = replaceStrAmis(that.amap_window_dom, markerInfo)


                      // //创建 infoWindow 实例
                      that.infoWindowObj = new AMap.InfoWindow({
                        isCustom: true, //使用自定义窗体
                        content: descriptionTem, //传入字符串拼接的 DOM 元素
                        // anchor: "bottom-center",
                        offset: new AMap.Pixel(16, -45),
                      });
                      // const { infoWindow } = createInfoWindow("<div class='info'>这是一个自定义窗体，所以内容和样式你都可以自定义<img src='https://webapi.amap.com/images/sharp.png'></div>");

                      //打开信息窗体
                      that.infoWindowObj.open(this.mapObj, position); //map 为当前地图的实例，map.getCenter() 用于获取地图中心点坐标。
                  }
              });

              markers.push(labelMarker);

            }
          })

          if(!this.amap_is_click) {
            // 创建 AMap.LabelsLayer 图层
            let layer = new AMap.LabelsLayer({
              zooms: [3, 20],
              zIndex: 111,
              // 关闭标注避让，默认为开启，v1.4.15 新增属性
              animation: false,
              // 关闭标注淡入动画，默认为开启，v1.4.15 新增属性
              collision: false
            });

            // 将图层添加到地图
            this.mapObj.add(layer);

            // 一次性将海量点添加到图层
            layer.add(markers);
            console.log('一次性将海量点添加到图层>>>>>>>>>>',markers);
            
          }

          if (this.center.length === 0) {
            this.center = [116.396304, 39.909847]
          }
        }


      } else {
        const lng = this.store.data[this.amap_longitude]
        // || 116.396304
        const lat = this.store.data[this.amap_latitude]
        // || 39.909847
        const label = this.store.data[this.amap_name]
        console.log('数据>>>>>>',this.$data.data);
        console.log('lng>>>>>>',lng);
        console.log('lat>>>>>>',lat);

        if (lng && lat) {
          this.center = [lng, lat]
          this.markers = [
            { lng: this.center[0], lat: this.center[1], name: label }
          ]
          console.log('markers>>>>>>',this.markers);

        } else {
          this.center = [116.396304, 39.909847]
        }
      }
    },
    /**
     * @desc 标记点点击事件
     * @param {  } 
     * @version: 1.0.0
     */
    markerClick(item) {
      console.log('标记点点击事件>>>>>>>>>>>>>>',item);
      
    },
    // 点击地图
    placeMarker (location) {
      console.log('location', location)
      console.log('MassMarkerLayer>>>>>>>>>>', this.MassMarkerLayer)
      let that = this;
      // this.clearOverlays()
      // 创建一个默认的 Marker
      // 为每个 marker 创建 DOM 元素。
      // const el = document.createElement('div');

      // const el2 = document.createElement('div');
      // el2.innerHTML = `<img :src="${require('./img/mark.png')}" style="width:19px;height:33px;" alt="" srcset="" @click="markerClick(${location})" />`;
      // el.appendChild(el2);
      let data1 = [{
        id: "1",
        name: "",
        icon: "markIcon",
        coordinates: [location.lng, location.lat],
      }]

      console.log('更新海量撒点数据>>>>>>>>>>>', data1);
      
      // 更新海量撒点数据
      this.MassMarkerLayer.setData(data1)
      
      // const marker1 = new aimap.Marker({
      //   element: el,
      //   pitchAlignment: 'map',
      //   rotationAlignment: 'map',
      // }).setLngLat([location.lng, location.lat]).addTo(this.aimapObj);
      //   marker1.on('click', (obj) => {
      //     console.log('marker1>>>>>>>>>>>', obj)
      //     // that.setValidDataHandle(marker1.getLngLat())
      //   })
      //   // this.mapObj.flyTo({
      //   //     center: [location.lng, location.lat],
      //   //     zoom: 11,
      //   //     speed: 1,
      //   //     curve: 2,
      //   //     easing(t) {
      //   //         return t;
      //   //     }
      //   // })
      // this.markers.push(marker1)
      // if (this.geocoder) {
      //   this.geocoder.geocode({
      //     'location': location
      //   }, (results, status) => {
      //     console.log('results', results, status)
      //     if (status === google.maps.GeocoderStatus.OK) {
      //       if (results[0]) {
      //         let address_components = results[0].address_components
      //         let province = ''
      //         let city = ''
      //         let district = ''
      //         let township = ''
      //         address_components.forEach((item,index) => {
      //           if(item.types.includes('route')) {
      //             // 街道/乡镇
      //             township = item.long_name
      //           }

      //           if(item.types.includes('sublocality_level_1')||(item.types.includes('locality')&&index==2)) {
      //             // 区/县
      //             district = item.long_name
      //           }

      //           if(item.types.includes('administrative_area_level_2')||(item.types.includes('locality')&&index==3)) { 
      //             // 市
      //             city = item.long_name
      //           }

      //           if(item.types.includes('administrative_area_level_1')) { 
      //             // 省/州
      //             province = item.long_name
      //           }

      //         })
      //         this.addressInfo = {
      //           address: results[0].formatted_address,
      //           lat: results[0].geometry.location.lat(),
      //           lng: results[0].geometry.location.lng(),
      //           province: province,
      //           city: city,
      //           district: district,
      //           township:township
      //         }
      //         this.setValidDataHandle(this.addressInfo)
      //         console.log(this.addressInfo)
      //       }
      //     } else {
      //       // alert('Geocoder failed due to: ' + status)
      //     }
      //   })
      // }
    },
    // 清除点
    clearOverlays () {
      if (this.markers.length > 0) {
        this.markers.forEach((marker) => {
          marker.remove()
        })
      }
    },
    geoCoder(positionArr) {
      // 获取地址经纬度和省市区
      var that = this
      // 这里通过高德 SDK 完成。
      // eslint-disable-next-line no-undef
      var geocoder = new AMap.Geocoder({
        radius: 1000,
        extensions: 'all'
      })
      console.log('positionArr>>>>>', positionArr)

      geocoder.getAddress(positionArr, (status, result) => {
        console.log('getAddress>>>>status>>>>>', status)
        console.log('getAddress>>>>result>>>>', result)

        if (status === 'complete' && result.info === 'OK') {
          if (result && result.regeocode) {
            const detailObj = {
              addressComponent: result.regeocode.addressComponent,
              detailedAddress: result.regeocode.formattedAddress,
              lng: positionArr[0],
              lat: positionArr[1]
            }

            that.amapInfo = detailObj
            console.log('markers>>>>>>>>>>>>>>>',that.markers);

            let markersArr = JSON.parse(JSON.stringify(that.markers))
            markersArr.push({
              lng: positionArr[0],
              lat: positionArr[1],
              name: result.regeocode.formattedAddress,
              address: result.regeocode.formattedAddress
            })

            that.markers = markersArr

            console.log('地址信息>>>>', detailObj)
            console.log('地址标记点信息>>>>', that.markers)
            console.log('地址标记点信息>>>>data', that.$data)
            console.log('地址标记点信息>>>>data', that.$data.amap_longitude)
            console.log('地址标记点信息>>>>data', that.$data.data)
            const dataCopy = JSON.parse(JSON.stringify(that.$data.data))
            dataCopy[that.$data.amap_longitude] = detailObj.lng
            dataCopy[that.amap_latitude] = detailObj.lat
            dataCopy[that.amap_info] = detailObj
            // that.$data.data = dataCopy
            that.$set(that.$data, 'defaultData', dataCopy)
            console.log('设置标记点之后的数据>>>>>>', that.$data)
            that.$emit('update:amap_info', detailObj)
            that.$data.amap_info = detailObj
            if(this.$schema.amap_info) {
              this.setValidData(this.$schema.amap_info, detailObj.detailedAddress)
            }

            if(this.$schema.amap_longitude) {
              this.setValidData(this.$schema.amap_longitude, detailObj.lng)
            }

            if(this.$schema.amap_latitude) {
              this.setValidData(this.$schema.amap_latitude, detailObj.lat)
            }

            if(this.$schema.province) {
              this.setValidData(this.$schema.province, detailObj.addressComponent.province)
            }

            if(this.$schema.city) {
              this.setValidData(this.$schema.city, detailObj.addressComponent.city || detailObj.addressComponent.province)
            }

            if(this.$schema.area) {
              this.setValidData(this.$schema.area, detailObj.addressComponent.district)
            }

            if(this.$schema.township) {
              this.setValidData(this.$schema.township, detailObj.addressComponent.township)
            }

            this.setValidData('amapDetailInfo', detailObj.addressComponent)
          }
        } else {
          if (that.errorInfo[result]) {
            that.$message.error(that.errorInfo[result])
          }
        }
      })


    },
    
    /**
     * @desc 添加脚本
     * @param {  } 
     * @version: 1.0.0
     */
    addScript() {
      // 添加脚本请求
      console.log('添加脚本请求>>>>>>',this.amap_center_lat);
      console.log('添加脚本请求>>>>>>',this.amap_center_lng);

      // const link = document.createElement('link');
      // script.rel = 'stylesheet';
      // script.src = '/style/aimap.css'; // 替换为你的脚本URL
      // document.head.appendChild(link);
      
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = '/js/aimap.js'; // 替换为你的脚本URL
      document.head.appendChild(script);
      
      // 如果需要在脚本加载后执行操作，可以监听load事件
      script.onload = () => {
        console.log('Script loaded successfully.');
        // 初始化地图
        this.mapInt()
        
        // 这里可以执行依赖于该脚本的代码
        // if(this.amap_center_lat&&this.amap_center_lng) {
        //   this.mapInit(Number(this.amap_center_lat),Number(this.amap_center_lng))
        // }else{
        //   this.mapInit()
        // }
      };
    },
    /**
     * 添加amis事件动作:
     * 在这里设置自定义组件对外暴露的动作，其他组件可以通过组件动作触发自定义组件的对应动作
     */
     doAction(action, args) {
      console.log('doAction>>>>>>>>>>>>',action)
      const actionType = action ? action.actionType : ''
      if (actionType === 'mapReload') {
        this.init()
      } else {
        console.log('自定义组件中监听到的事件动作：', action, ', 事件参数：', args)
      }
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep{
  .el-vue-search-box-container {
    width: 270px;
  }
}
.amap-box {
  ::v-deep{
    .amap-marker-label {
      transform: translate(-40%, -24px);
      border: none;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
    }
  }
}

.amap-wrapper {
  text-align: left;
  position: relative;
}
.amap-box {
  width: 90%;
  // margin-left: 5%;
}
.search-box {
  width: 30%;
  position: absolute;
  top: 15px;
  left: 3%;
  z-index: 999;
}

.info {
  background-color: #fff;
  text-align: center;
  padding: 10px;
  position: relative;
  border: 1px solid #b9b9b9;
}
.info img {
  width: 30px;
  height: 23px;
  position: absolute;
  left: calc(50% - 15px);
  bottom: -23px;
}

#map {
  height: 100%;
}

#result_container {
  position: absolute;
  top: 0;
  background: #fff;
}
.places_wrapper {
  max-height: 200px;
  width: 250px;
  font-size: 12px;
  overflow: auto;
  margin: 5px 0;
}
.place {
  line-height: 32px;
  padding: 0 10px;
  border-bottom: 1px solid #eee;
}
</style>
