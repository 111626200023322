<template>
  <div class="cham-qrcode">
    <div class="cham-qrcode__header">
      <span class="cham-qrcode__header-title">尺寸：</span>
      <a-radio-group v-model="sizeValue" :disabled="loading" button-style="solid">
        <a-radio-button :value="item.value" :key="item.value" v-for="item in sizeOptions">{{item.label}}</a-radio-button>
      </a-radio-group>
    </div>
    
    <div class="cham-qrcode__header">
      <span class="cham-qrcode__header-title">枪排序方式：</span>
      <a-radio-group v-model="sortType" :disabled="loading" button-style="solid">
        <a-radio-button :value="item.value" :key="item.value" v-for="item in sortTypeOptions">{{item.label}}</a-radio-button>
      </a-radio-group>
    </div>
    <div class="cham-qrcode__header">
      <span class="cham-qrcode__header-title">设备类型：</span>
      <a-input style="margin-left: 11px;" :disabled="loading" v-model="deiviceType" placeholder="输入设备类型"></a-input>

    </div>
    
    <div class="cham-qrcode__header">
      <span class="cham-qrcode__header-title">背景颜色：</span>
      <input type="color" name="color" :disabled="loading" v-model="color" @change="changeColor">
    </div>
    <div class="cham-qrcode__header">
      <span class="cham-qrcode__header-title">图片后缀：</span>
      <a-radio-group v-model="imageValue" :disabled="loading" button-style="solid">
        <a-radio-button :value="item.value" :key="item.value" v-for="item in imageTypeOptions">{{item.label}}</a-radio-button>
      </a-radio-group>
    </div>
    <div class="cham-qrcode__header">
      <a-button v-if="createIng" type="primary" :loading="createIng">二维码生成中</a-button> 
      <a-button v-if="!createIng" type="primary" :loading="loading" @click="createQrCode">下载二维码{{ loading?'('+createNum+'/'+imageDataAry.length+')':'' }}</a-button>
    </div>
    <div v-if="loading" class="cham-qrcode__progress">
      <a-progress :percent="progress" />
    </div>
    <div class="cham-qrcode__content">
      <div v-for="(item, index) in imageDataAry" :id="'qrCode_'+item.deviceNo" :key="index" class="cham-qrcode__qrcode" >
        <div class="cham-qrcode__item" :id="'qrCode_item_'+item.deviceNo"  :style="{'transform': sizeValue==1?'scale(0.5)':sizeValue==2?'scale(0.75)':sizeValue==4?'scale(0.3)':'scale(1)'}">
          <div class="cham-qrcode__qrcode-title" :style="{'color':color}">
            <div style="display: flex;align-items: flex-end;">
              <span class="cham-qrcode__qrcode-title-dh">{{sortType==1?item.unitName:item.unitAddr}}</span>
              <span style="font-size: 16px;">枪</span>
            </div>
            <span style="font-size: 16px;">{{item.deiviceType?(deiviceType||item.deiviceType):(deiviceType||'直流')}}</span>
          </div>
          <div class="cham-qrcode__qrcode-img" :style="{'borderColor':color}">
            <span>{{ item.deviceNo }}</span>
            <img style="width: 250px;height: 250px;" :src="item.qrcodeUrl" alt="" srcset="">
          </div>
          <div class="cham-qrcode_bottom" :style="{'background':color}">
            <div class="cham-qrcode__bottom_bg"></div>
            <div class="cham-qrcode__bottom_logo">
              <img :src="tenantLogo" alt="" srcset="">
              <span>{{tenantName}}</span>
            </div>
            <span v-if="dhDz" class="cham-qrcode__bottom_dh">24小时客服热线：{{ dhDz | PhoneFormat }}</span>
          </div>

        </div>
        <span>{{ item.deviceName }}-{{ item.deviceNo }}</span>
      </div>

    </div>
    
    <!-- <div id="printer" :ref="`qrCode`" style="display:none" class="codeStyle" />
    {{qrData}}
    <button @click="createQrCode">生成二维码</button> -->
  </div>
</template>

<script>

import JSzip from 'jszip'
import { saveAs } from 'file-saver'
// import QRCode from 'qrcodejs2';
import QRCode from 'qrcode'
import { replaceStrAmis } from '@/utils/util'
// import { mapGetters } from 'vuex'
import { getPtkf, getInfo } from '@/api/login'
import html2canvas from 'html2canvas';
export default {
  components: {
  },
  data () {
    return {
      // qrData: [],
      letters: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split(''), // 字母
      color: '#1eb393', // 默认颜色
      sizeOptions:[
        {
          value:1,
          label:'227*251'
        },
        {
          value:2,
          label:'341*377'
        },
        {
          value:3,
          label:'455*503'
        },
        // {
        //   value:4,
        //   label:'75*83'
        // },
        {
          value:4,
          label:'126*141'
        },
        // {
        //   value:4,
        //   label:'910*1006'
        // },
      ],
      sizeValue:3, // 尺寸
      sortTypeOptions:[
        {
          value:1,
          label:'按字母'
        },
        {
          value:2,
          label:'按数字'
        }
      ],
      imageValue:1, // 图片后缀
      imageTypeOptions:[
        {
          value:1,
          label:'jpg'
        },
        {
          value:2,
          label:'png'
        }
      ],
      sortType:1, // 枪排序方式
      imageDataAry: [], // 二维码图片数据
      dhDz: null, // 客服电话
      userInfo: null, // 用户信息
      deiviceType:'', // 设备类型
      loading:false, // 下载二维码中
      createIng:false, // 是否生成二维码中
      qrcodeCreated:false, // 二维码是否生成
      createNum:0,
      
      progress: 0, // 进度百分比
      totalItems: 0, // 总项目数
      completedItems: 0, // 已完成项目数
      tenantLogo: null,
      getDhDz: false // 获取客服电话
    };
  },
  computed: {
    // ...mapGetters(['userInfo']),
    qrData () {
      return this.$data.data[this.data_list]||[]
    },
    
    // tenantLogo() {
    //   return this.userInfo?.tenantInfo?.tenantLogo||null
    // },
    tenantName() {
      return this.userInfo?.tenantInfo?.tenantName||''
    }
  },
  mounted () {

  },
  // watch: {
  //   qrData: {
  //     deep: true,
  //     handler (val) {
  //       // console.log('>>>>>>>>>>',val);
        
  //       this.createQrCodeImg(val)
  //     },

  //   }
  // },
  watch: {
    qrData: {
      deep: true,
      handler (val) {
        this.createQrCodeImg(val)
      }
    }
  },
  created () {
    this.getInfo()
  },  
  methods: {
    changeColor(color) {
      // console.log('选择的颜色:', color);
    },
    getInfo(){
      // /system/config/get/ptkf
      this.createIng = true
      this.getDhDz = false
      getPtkf().then(res => {
        // console.log('res>>>>>>>>>>>',res);
        if(res.code == "20001") {
          this.dhDz = res.data.dhDz
          this.getDhDz = true
        }
        this.endCreateIng()

      })
      getInfo().then(async res => {
        // console.log('getInfo>>>>>>>>>>>',res);
        if(res.code == "20001") {
          this.userInfo = res.data

          try {
            const response = await fetch(this.userInfo?.tenantInfo?.tenantLogo);
            const blob = await response.blob();
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
              const base64data = reader.result;
              // console.log('输出Base64编码的图片>>>>>>>',base64data); // 输出Base64编码的图片
              this.tenantLogo = base64data
            };
          } catch (error) {
            console.error('Error converting image to Base64:', error);
          }
        }
        this.endCreateIng()
      })
    },
    /**
     * @desc 结束生成二维码
     * @param {  } 
     * @version: 1.0.0
     */
    endCreateIng() {
      if(this.getDhDz&&this.tenantLogo&&this.qrcodeCreated) {
        let timeLenghth = this.imageDataAry.length*200
        if(timeLenghth<2000) {
          timeLenghth = 2000
        }
        setTimeout(() => {
          this.createIng = false
        }, timeLenghth);

      }
      // console.log('结束生成二维码>>>>>>>>>createIng>>>>>>>>>>>',this.createIng);
      // console.log('结束生成二维码>>>>>>>>>dhDz>>>>>>>>>>>',this.dhDz);
      // console.log('结束生成二维码>>>>>>>>>userInfo>>>>>>>>>>>',this.userInfo);
      
    },
    /**
     * @desc 生成二维码图片
     * @param {  } 
     * @version: 1.0.0
     */
    createQrCodeImg (val) {
      let that = this
      that.imageDataAry = []
      this.qrcodeCreated = false
      this.createIng = true
      // console.log('生成二维码图片>>>>>>>>>>',val);
      
      val.forEach((item,index) => {
        if(item.parentId!=0) {
          // console.log('url>>>>>>>>>>>>>', replaceStrAmis(that.qrcode_url, item));
          // console.log('userInfo>>>>>>>>>>>>>', that.$store);
          QRCode.toDataURL(replaceStrAmis(that.qrcode_url, item), { width: 460, height: 460,correctLevel:'H' }, function (err, url) {
            if (err) {
              // console.log('err>>>>>>>>>>>>',err)
            } else {
              // console.log('url>>>>>>>>>>>',url)
              that.imageDataAry.push({
                qrcodeUrl: url,
                deviceName: item.deviceName,
                deviceNo: item.deviceNo,
                unitAddr: item.unitAddr,
                unitName: that.letters[item.unitAddr-1],
                deiviceType:item.chargeType==1?'交流':'直流',
                // deiviceType:item.modelName,
                
                // sysTenantUrl: that.userInfo?.tenantInfo?.sysTenantUrl||'',
                // sysTenantName: that.userInfo?.tenantInfo?.tenantName||'',
                dhDz:that.dhDz
              })
              
              if(index == val.length-1) {
                that.qrcodeCreated = true
                that.endCreateIng()
              }
              // console.log('createIng>>>>>>>>>>>',that.createIng)
              // console.log('getTime>>>>>>>>>>>',new Date().getTime())
              // this.imageDataAry.push(url)
              // this.imageDataAry.push(url)
            // this.imageDataAry.push(url)
            }
          })
        }else{
          if(index == val.length-1) {
            that.qrcodeCreated = true
            that.endCreateIng()
          }
        }
        
      })
      // setTimeout(() => {
      //   // that.createIng = false
      //   that.endCreateIng()

      // }, 200*val.length);
    },
    // createQrCode () {
    //   let that = this
    //   that.loading = true
    //   function greet () {
    //     // 数据多的话，异步执行二维码生成。
    //     var promise = new Promise(async function (resolve, reject) {

    //       let imageDataAry = []

    //       if(that.imageDataAry.length == 0) {
    //         that.$message.error('请先生成二维码')
    //         return
    //       }
    //       // 逐个生成二维码图片
    //       that.imageDataAry.forEach(async (item, index) => {
    //         let id = '#qrCode_item_'+item.deviceNo
    //         let itemObj = JSON.parse(JSON.stringify(item))
    //         // const canvas = await html2canvas(document.querySelector(id),{
    //         //   scale: 2, // 提供更高的分辨率
    //         //   useCORS: true // 允许跨域图片处理
    //         // })
    //         html2canvas(document.querySelector(id),{
    //           scale: 2, // 提供更高的分辨率
    //           useCORS: true // 允许跨域图片处理
    //         }).then(canvas => {
    //           // console.log('canvas>>>>>>>>>>>',canvas);
    //           // 生成图片
    //           const imgData = canvas.toDataURL('image/png');
    //           // console.log('存储二维码base64数据>>>>>>>>>>>',imgData);
    //           // 存储二维码base64数据
    //           itemObj['imgData'] = imgData
    //           imageDataAry.push(itemObj)
              
    //           that.createNum = imageDataAry.length
    //           // console.log('存储二维码base64数据>>>>imageDataAry>>>>>>>',imageDataAry);
    //           if (imageDataAry.length === that.imageDataAry.length) {
    //             // console.log('存储二维码base64数据>>>>imageDataAry>>>>1>>>',imageDataAry);
    //             resolve(imageDataAry)
    //           }
    //         })
            
    //       })
    //     })
    //     return promise
    //   }
    //   // 执行zip操作
    //   setTimeout(() => {
    //     greet().then(v => {
    //       // console.log('执行zip操作>>>>>>',v)

    //       // zip 实例
    //       const zip = new JSzip()

    //       v.forEach((item, index) => {

    //         // base64数据转换
    //         const blob = this.base64ToBlob(item.imgData);
    //         // console.log(blob)
    //         // 二进制数据存入 zip 实例
    //         zip.folder('二维码文件').file(`二维码_${item.deviceName}_${item.deviceNo}.png`, blob, { binary: true })

    //       })

    //       // 生成二进制流
    //       zip.generateAsync({
    //         type: 'blob'
    //       }).then(content => {
    //         that.loading = false
    //         // 保存zip文件
    //         saveAs(content, '二维码文件.zip')
    //       }).catch(err => {
    //         that.loading = false
    //         console.error(err)
    //       })
    //     }).catch(err => {
    //       that.loading = false
    //     })
    //   },1000)
    // },
    async createQrCode() {
      let that = this;
      that.loading = true;
      that.createNum = 0; // 重置生成数量
      that.progress = 0; // 重置进度
      that.totalItems = that.imageDataAry.length;
      that.completedItems = 0;

      setTimeout(async () => {
        try {
          if (that.imageDataAry.length === 0) {
            that.$message.error('请先生成二维码');
            that.loading = false;
            return;
          }
          const zip = new JSzip();
          const batches = await that.batchProcess(that.imageDataAry);
          for (const batch of batches) {
            const processedBatch = await that.processBatch(batch);
            for (const item of processedBatch) {
              // const blob = that.base64ToBlob(item.imgData);
              zip.folder('二维码文件').file(`二维码_${item.deviceName}_${item.deviceNo}.${this.imageValue==1?'jpg':'png'}`, item.imgData, { binary: true });
              that.createNum++;
              that.completedItems++;
              that.progress = (that.completedItems / that.totalItems).toFixed(2) * 100;
            }
          }
          // const content = await zip.generateAsync({ type: 'blob' });
          // saveAs(content, '二维码文件.zip');
          // 生成二进制流
          setTimeout(() => {
            zip.generateAsync({
              type: 'blob'
            }).then(content => {
              that.loading = false
              // 保存zip文件
              saveAs(content, '二维码文件.zip')
            }).catch(err => {
              that.loading = false
              console.error(err)
            })
          }, 100);
        } catch (err) {
          console.error(err);
        } finally {
          that.loading = false;
        }
      }, 100);
    },
    async batchProcess(imageDataAry, batchSize = 10) {
      const batches = [];
      for (let i = 0; i < imageDataAry.length; i += batchSize) {
        batches.push(imageDataAry.slice(i, i + batchSize));
      }
      return batches;
    },
    async processBatch(batch) {
      const promises = batch.map(async (item) => {
        let id = '#qrCode_item_' + item.deviceNo;
        let itemObj = JSON.parse(JSON.stringify(item));
        const canvas = await html2canvas(document.querySelector(id), {
          scale: 2,
          dpi: 700,
          // width: 126,// 455*503  126*141
          // height: 141,
          useCORS: true
        });
        const imgBlob = await new Promise((resolve, reject) => {
          if(this.imageValue==1) {
            canvas.toBlob(resolve, 'image/jpeg');
          }else{
            canvas.toBlob(resolve, 'image/png');
          }
        });
        // const imgData = canvas.toBlob('image/png');
        itemObj['imgData'] = imgBlob;
        return itemObj;
      });
      return Promise.all(promises);
    },
    base64ToBlob (code) {
      let parts = code.split(';base64,');
      let contentType = parts[0].split(':')[1];
      let raw = window.atob(parts[1]);
      let rawLength = raw.length;
      let uInt8Array = new Uint8Array(rawLength);
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      return new Blob([uInt8Array], {
        type: contentType
      });
    },
  },
}
</script>
<style lang="less" scoped>
.cham-qrcode {
  width: 100%;
  height: 100%;
  .cham-qrcode__header {
    display: flex;
    justify-content: space-start;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 10px;

    .cham-qrcode__header-title {  
      margin-right: 10px;
      width: 100px;
    }
    
  }

  .cham-qrcode__progress {
    padding-right: 20px;
  }

  .cham-qrcode__content {
    display: flex;
    flex-wrap: wrap;

    .cham-qrcode__qrcode {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
      margin-bottom: 20px;

      .cham-qrcode__item {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        width: 455px;
        height: 503px;
        background: #fff;
        overflow: hidden;

        .cham-qrcode__qrcode-title {
          position: relative;
          width: 220px;
          display: flex;
          justify-content: space-between;
          align-items: end;
          padding: 0 10px;
          color: #1eb393;
          z-index: 9999;

          .cham-qrcode__qrcode-title-dh {
            font-size: 50px;
            font-weight: bold;
            font-family: '思源-Bold';
            margin-right: 16px;
            margin-bottom: -12px;
            margin-top: 0;
          }
        }
        
        .cham-qrcode__qrcode-img {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 15px 0 0;
          margin-top: 10px;
          border: 4px solid #1eb393;
          border-radius: 24px;
          margin-bottom: -10px;
          z-index: 9999;
          overflow: hidden;
          span {
            position: relative;
            font-size: 18px;
            color: #000;
            margin-top: 0;
            margin-bottom: -15px;
            z-index: 9999;
          }
        }

        .cham-qrcode_bottom {
          position: relative;
          // position: absolute;
          // bottom: 0;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
          width: 100%;
          height: 155px;
          padding-bottom: 24px;
          background: #1eb393;
          // overflow: hidden;
          border: none;

          .cham-qrcode__bottom_bg {
            position: absolute;
            top: -1979px;
            width: 2006px;
            height: 2006px;
            background: #fff;
            border-radius:50%;
          }

          .cham-qrcode__bottom_logo {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 60px;
              height: 60px;
            }
            span {
              font-size: 24px;
              font-weight: bold;
              margin-left: 10px;
            }
          }

          .cham-qrcode__bottom_dh {
            font-size: 18px;
            color: #000;
            margin-top: 10px;
          }
            
        }
      }
      span {
        margin-top: 10px;
      }
    }

    
  }
}
</style>