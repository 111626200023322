// 注册自定义组件渲染器
import { registerRendererByType } from '@/utils/amis-widget/index.esm.js'
import Aimap from './aimap.vue'

registerRendererByType(Aimap, {
  type: 'aimap',
  usage: 'formitem',
  weight: 99,
  framework: 'vue',
  detectProps: ['aimap_info']
})
